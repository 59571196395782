<template>
  <div class="container">
    <div class="curriculum-optional-component mt-4">
      <h4 class="text-center">Утверждение индивидуальных учебных планов студентов</h4>

      <!-- filter form -->
      <div>
        <div class="row mt-4">
          <label class="col-md-3 col-form-label">Год набора</label>
          <div class="col-md-9">
            <select class="form-control form-select" v-model="studyCourse">
              <option v-for="(sCourse, sCourseIndex) in [{year: 'Выберите год набора', course:0},...Object.values(courses)]"
                      :key="sCourseIndex"
                      :value="sCourse.course">
                {{ sCourse.course !== 0 ? (sCourse.year + "("+sCourse.course+")") : sCourse.year}}
              </option>
            </select>
          </div>
        </div>
        <div class="row mt-4">
          <label class="col-md-3 col-form-label">Уровень обучения</label>
          <div class="col-md-9">
            <select class="form-control form-select" v-model="studyLevelId">
              <option
                v-for="(item, index) in [{id: 0, name: 'Выберите уровень обучения'}, ...docOrdersStudents_form.studyLevels]"
                :value="item.id"
                :key="index">{{ item.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="row mt-4">
          <label class="col-md-3 col-form-label">Язык обучения</label>
          <div class="col-md-9">
            <select class="form-control form-select" v-model="studyProgramLanguage">
              <option
                v-for="(item, index) in [{id: '', native_name: 'Выберите язык ОП'}, ...studyLanguages]"
                :value="item.id"
                :key="index">
                {{item.native_name}}
              </option>
            </select>
          </div>
        </div>
        <div class="row mt-4">
          <label class="col-md-3 col-form-label">Тип ОП</label>
          <div class="col-md-9">
            <select class="form-control form-select" v-model="studyModuleType">
              <option
                v-for="(item, index) in [{id: 0, name: 'Выберите тип ОП'}, ...moduleTypes]"
                :value="item.id"
                :key="index">{{ item.name }}
              </option>
            </select>
          </div>
        </div>
        <div v-if="studyCourse&&studyLevelId&&studyModuleType&&studyProgramLanguage&&studyModuleType === 2" class="row mt-4">
          <label class="col-md-3 col-form-label">Тип Minor</label>
          <div class="col-md-9">
            <select class="form-control form-select" v-model="studyMinorType">
              <option
                v-for="(item, index) in [{id: 0, name: 'Выберите тип Minor'}, ...minorTypes]"
                :value="item.id"
                :key="index">{{ item.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <!-- end filter form -->
      <div v-if="studyCourse&&studyLevelId&&studyModuleType&&studyProgramLanguage&&(studyModuleType === 2 ? studyMinorType !== 0 : true)">
        <!-- select education program section -->
        <div>
          <div class="row mt-4">
            <label class="col-md-3 col-form-label">Образовательная программа</label>
            <div class="col-md-9">
              <select class="form-control form-select" v-model="educationProgramCode">
                <option
                  v-for="(item, index) in [{id: '', education_speciality_code: '', education_speciality_name: 'Выберите ОП', admission_year:'год'}, ...groupedEducationPrograms]"
                  :value="studyMinorType !== 0 ? item.id : item.education_speciality_code"
                  :key="index">
                  {{item.education_speciality_code}} {{item.education_speciality_name}} - {{item.admission_year}}
                </option>
              </select>
            </div>
          </div>

          <div class="mt-4 text-center">
            <Button label="Выбрать" class="p-button-success me-2" @click="selectEducationProgram()"/>
            <Button label="Очистить" class="p-button-warning" @click="clear"/>
          </div>
        </div>
        <!-- end select education program section -->

        <!-- education program students count info section -->
        <div v-if="Object.keys(optionalComponent).length&&educationProgramCode" class="row col-md-12 my-3 ">
          <h4 class="text-center m-0">
            Количество студентов обучающиеся в ОП: <b>{{Object.values(optionalComponent)[0]?.should_choose}}</b>
          </h4>
        </div>
        <!-- end education program students count info section -->

        <!-- education program pagination section -->
        <div class="my-3 text-center p-2" v-if="educationProgramCode&&Object.keys(educationProgramPaginate).length">
          <Button :label="educationProgramPaginate.prev.name" icon="pi pi-angle-left"
                  @click="selectEducationProgram(educationProgramPaginate.prev.code)"
                  class="mx-2" :disabled="educationProgramPaginate.prev.disabled"
                  style="width: 240px"/>
          <Button :label="educationProgramPaginate.next.name" icon="pi pi-angle-right"
                  @click="selectEducationProgram(educationProgramPaginate.next.code)"
                  class="mx-2" iconPos="right" :disabled="educationProgramPaginate.next.disabled"
                  style="width: 240px"/>
        </div>
        <!-- end education program pagination section -->


        <!-- disciplines section -->
        <div class="my-4">
          <div v-if="loadingDisciplines" class="d-flex justify-content-center">
            <Preloader/>
          </div>
          <div v-else-if="Object.keys(optionalComponent).length&&educationProgramCode">
            <TabView @tab-change="onTabModuleChange">
              <TabPanel v-for="(module, moduleKey) in optionalComponent.modules" :key="moduleKey" :header="moduleKey">
                <div v-for="(semester, semesterKey) in module?.semesters" :key="semesterKey">
                  <div v-if="semester && Object.keys(semester).length" class="mb-4">

                    <SemesterHeader :sumCount="semester.sum_count" :semesterKey="semesterKey" :shouldChoose="module.should_choose" :credit="semester.credit"/>

                    <div class="row border border-2 rounded-3 mt-2 mb-3 py-2 mx-1 border-light-blue"
                         v-for="(basicDiscipline, basicDisciplineIndex) in semester['components'].w_kv" :key="basicDisciplineIndex">
                      <div class="row my-1">
                        <div class="col-md-5">
                          <div class="row">
                            <div class="col-12 col-md-2">
                              <span class="px-2 fs-5 bg-light-blue rounded-3">
                                {{ basicDisciplineIndex + 1 }}
                              </span>
                            </div>
                            <div class="col-12 fs-5 col-md-10">
                              {{ basicDiscipline.discipline_name }}
                            </div>
                          </div>
                        </div>
                        <div class="col-md-1 fs-5 d-flex align-items-center">
                          {{basicDiscipline.credit}}
                        </div>
                        <div v-if="basicDiscipline.students.length && !optionalComponent.is_created" class="col-md-6 mb-2">
                          <p @click="openStudentChooseWKVModal(basicDisciplineIndex, semesterKey, moduleKey, 'w_kv')"
                             class="text-primary text-decoration-underline fs-5 text-center"
                             style="cursor: pointer">Сделать выбор за невыбравших</p>
                        </div>
                        <div v-else class="col-md-6 mb-2 fs-5">
                          Данная дисциплина не требует каких либо действий
                        </div>
                      </div>
                    </div>

                    <div class="border border-2 rounded-3 mt-2 mb-3 row py-2 mx-1"
                         v-for="(fGroup, fGroupIndex, index) in semester['components'].kv" :key="fGroupIndex"
                         :style="{backgroundColor: colorForFGroup[fGroup['disciplines'][0].f_group_id]||'#fff'}">
                      <template v-for="(eDiscipline, eDisciplineIndex) in fGroup['disciplines']" :key="eDisciplineIndex">
                        <div class="row my-1">
                          <div class="col-md-5 d-flex align-items-center">
                            <div class="row w-100">
                              <div class="col-12 col-md-2">
                                <span v-if="eDisciplineIndex===0" class="px-2 fs-5 bg-light-blue rounded-3">
                                  {{ semester['components'].w_kv.length + index + 1 }}
                                </span>
                              </div>
                              <div class="col-12 fs-5 col-md-10">
                                {{eDiscipline.discipline_name}}
                              </div>
                            </div>
                          </div>
                          <div class="col-md-1 fs-5 d-flex align-items-center">
                              {{ eDiscipline.credit }}
                          </div>
                          <div class="row col-md-3 mb-2 h6 d-flex align-items-center">
                            <div class="col-12">
                              <b>В этом ОП:</b> {{findPercentageOfTotalShouldChoose(eDiscipline.count, module.should_choose)}}
                            </div>
<!--                            <div class="col-12">-->
<!--                              <b>В других ОП:</b> {{findPercentageOfTotalShouldChoose(eDiscipline.count, eDiscipline.other_chooses)}}-->
<!--                            </div>-->
                          </div>
                          <div v-if="electiveDisciplinesModel[semesterKey]" class="col-md-3 mb-2 fs-5 text-center d-flex flex-column">
                            <Button v-if="eDiscipline.component_status == 0"
                                    :disabled="optionalComponent.is_created || btnConfirming.status && btnConfirming.discipline_code === eDiscipline.discipline_code"
                                    label="Принять выбор" class="p-button-raised mb-2 w-100"
                                    @click="confirmDisciplineStatus(eDiscipline.formation_education_program_id, eDiscipline.discipline_code, semesterKey, eDiscipline.f_group_id)"
                                    :icon="(btnConfirming.status && btnConfirming.discipline_code === eDiscipline.discipline_code) ? 'pi pi-spin pi-spinner' : ''"
                                    iconPos="right"/>
                            <Button v-else
                                    :disabled="optionalComponent.is_created || btnConfirming.status && btnConfirming.discipline_code === eDiscipline.discipline_code"
                                    class="p-button-raised p-button-warning mb-2 w-100" label="Отменить сохранение"
                                    @click="confirmDisciplineStatus(eDiscipline.formation_education_program_id, eDiscipline.discipline_code, semesterKey, eDiscipline.f_group_id, 0)"
                                    :icon="(btnConfirming.status && btnConfirming.discipline_code === eDiscipline.discipline_code) ? 'pi pi-spin pi-spinner' : ''"
                                    iconPos="right">
                            </Button>
                            <Button v-if="eDiscipline.count != 0" :disabled="optionalComponent.is_created"
                                    @click="cancelDisciplineChooses({
                                    delete_formation_id: eDiscipline.formation_education_program_id,
                                    change_formation_id: electiveDisciplinesModel[semesterKey][eDiscipline.f_group_id].formationEducationProgramIds.find(item => item != eDiscipline.formation_education_program_id),
                                    discipline_code: eDiscipline.discipline_code})"
                                    label="Отклонить выбор" class="p-button-raised p-button-danger w-100"/>
                          </div>
                        </div>
                        <hr v-if="eDisciplineIndex!==(fGroup['disciplines'].length-1)" class="hr-light-blue my-2">
                      </template>
                      <div v-if="electiveDisciplinesModel[semesterKey] && semester['components'].kv[fGroupIndex].students.length
                                  && !optionalComponent.is_created" class="row">
                        <div class="col-12">
                          <p @click="openStudentChooseKVModal(fGroupIndex, semesterKey, moduleKey)"
                             class="text-primary text-decoration-underline fs-5 text-center mb-1"
                             style="cursor: pointer">Сделать выбор за невыбравших</p>
                        </div>
                        <div class="col-12">
                          <p @click="openStudentChooseToMinorModal()"
                             class="text-primary text-decoration-underline fs-5 text-center mb-1"
                             style="cursor: pointer">Перенести студентов на майнор</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </TabView>

            <TabView v-if="chosenMinorTabIndex === 1">
              <TabPanel v-for="(minor, minorKey) in optionalComponent?.modules.minor" :key="minorKey" :header="minorKey">
                <div v-for="(semester, semesterKey) in minor?.semesters" :key="semesterKey">
                  <div v-if="semester && Object.keys(semester).length" class="mb-4">

                    <SemesterHeader :sumCount="semester.sum_count" :semesterKey="semesterKey" :shouldChoose="minor.should_choose" :credit="semester.credit"/>

                    <div class="row border border-2 rounded-3 mt-2 mb-3 py-2 mx-1 border-light-blue"
                         v-for="(basicDiscipline, basicDisciplineIndex) in semester['components'].w_kv" :key="basicDisciplineIndex"
                         :class="{'bg-minor': basicDiscipline.is_minor == 1}">
                      <div class="row my-1">
                        <div class="col-md-5">
                          <div class="row">
                            <div class="col-12 col-md-2">
                              <span class="px-2 fs-5 bg-light-blue rounded-3">
                                {{ basicDisciplineIndex + 1 }}
                              </span>
                            </div>
                            <div class="col-12 fs-5 col-md-10">
                              {{ basicDiscipline.discipline_name }}
                            </div>
                          </div>
                        </div>
                        <div class="col-md-1 fs-5 d-flex align-items-center">
                          {{basicDiscipline.credit}}
                        </div>
                        <div class="col-md-6 mb-2 fs-5">
                          Данная дисциплина не требует каких либо действий
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </TabView>



            <div class="text-center mt-2 mb-5">
              <div v-if="!isApproved">
                <button type="button" class="btn btn-primary m-1 mb-4 ms-2" @click="openRupModal()"
                        :disabled="optionalComponent.is_created||btnApproveStatus||btnStatus">
                  <span v-if="btnApproveStatus" class="spinner-grow spinner-grow-sm" role="status"
                        aria-hidden="true"></span>
                  {{ optionalComponent.is_created ? 'РУП создан' : 'Создать РУП' }}
                </button>
              </div>
              <div v-else>
                <h5>Утверждено</h5>
              </div>
            </div>
          </div>
        </div>
        <!-- end disciplines section -->
      </div>
    </div>

    <Dialog header="Перенос студентов на майнор" v-model:visible="studentsChooseToMinor.studentsChooseToMinorModal"
            :style="{width: '98%', maxWidth: '900px'}" :modal="true" :closable="false">
      <div>
        <div class="mb-2">
          <h5>Дисциплины с которых переносятся студенты:</h5>
          <p class="mb-1" v-for="(discipline, disciplineKey) in studentsChooseToMinor.studentsChooseToMinorData.f_group_disciplines"
             :key="disciplineKey"><span class="fw-bold">{{disciplineKey+1}}.</span> {{discipline.discipline_name}}</p>
        </div>
        <div>
          <h5>Дисциплина на которую переносятся студенты:</h5>
          <p class="mb-1" v-for="(discipline, disciplineKey) in studentsChooseToMinor.studentsChooseToMinorData.minor_group_disciplines"
             :key="disciplineKey"><span class="fw-bold">{{disciplineKey+1}}.</span> {{discipline.discipline_name}}</p>
        </div>
      </div>
      <template #footer>
        <Button label="Закрыть" icon="pi pi-times" class="p-button-text p-button-danger" @click="closeStudentChooseToMinorModal"/>
        <Button label="Перенести выборность" icon="pi pi-check" class="p-button-text" @click="changeStudentChooseToMinor"/>
      </template>
    </Dialog>

    <Dialog header="Выбор студентов" v-model:visible="studentsChoose.w_kv.studentsChooseChangeModal"
            :style="{width: '98%', maxWidth: '900px'}" :modal="true" :closable="false">
      <div>
        <div class="mb-3">
          <p class="h5">Дисциплина: {{ studentsChoose.w_kv.studentsChooseChangeData.discipline_name }}</p>
        </div>
        <h5>Невыбравшие студенты:</h5>
        <div class="row row-cols-1 row-cols-md-3 row-cols-sm-2">
          <div class="d-flex align-items-center mt-2 col"
               v-for="(student, studentIndex) in studentsChoose.w_kv.studentsChooseChangeData.students" :key="studentIndex">
            <Checkbox name="students" :value="student.id" v-model="studentsChoose.w_kv.studentsChooseChangePostData.students"/>
            <p class="ms-2 mb-0 d-inline">{{student.last_name}} {{student.first_name}}</p>
          </div>
        </div>
      </div>
      <template #footer>
        <Button label="Закрыть" icon="pi pi-times" class="p-button-text p-button-danger" @click="closeStudentChooseWKVModal"/>
        <Button label="Создать выборность" icon="pi pi-check" class="p-button-text" @click="createStudentChooseWKV"/>
      </template>
    </Dialog>

    <Dialog header="Выбор студентов" v-model:visible="studentsChoose.kv.studentsChooseChangeModal"
            :style="{width: '98%', maxWidth: '900px'}" :modal="true" :closable="false">
      <div>
        <h5>Невыбравшие студенты:</h5>
        <div class="row row-cols-1 row-cols-md-3 row-cols-sm-2">
          <div class="d-flex align-items-center mt-2 col"
               v-for="(student, studentIndex) in studentsChoose.kv.studentsChooseChangeData.students" :key="studentIndex">
            <Checkbox name="students" :value="student.id" v-model="studentsChoose.kv.studentsChooseChangePostData.students"/>
            <p class="ms-2 mb-0 d-inline">{{student.last_name}} {{student.first_name}}</p>
          </div>
        </div>
      </div>
      <div class="fw-bold mt-4">
        <h5>Выберите дисциплину:</h5>
        <div class="row h5">
          <div class="col-1">
            №
          </div>
          <div class="col-2">
            <span class="material-symbols-outlined">pan_tool_alt</span>
          </div>
          <div class="col-9">
            Название дисциплины
          </div>
        </div>
      </div>
      <div class="row my-2" v-for="(discipline, disciplineIndex) in studentsChoose.kv.studentsChooseChangeData.disciplines" :key="disciplineIndex">
        <div class="col-1 fw-bold">
          {{ disciplineIndex + 1 }}
        </div>
        <div class="col-2">
          <RadioButton name="formation_education_program_id" :value="discipline.formation_education_program_id" v-model="studentsChoose.kv.studentsChooseChangePostData.formation_education_program_id"/>
        </div>
        <div class="col-9">
          <label>{{discipline.discipline_name}}</label>
        </div>
      </div>
      <template #footer>
        <Button label="Закрыть" icon="pi pi-times" class="p-button-text p-button-danger" @click="closeStudentChooseKVModal"/>
        <Button label="Создать выборность" icon="pi pi-check" class="p-button-text" @click="createStudentChooseKV"/>
      </template>
    </Dialog>

    <Dialog header="Утверждение РУП-а" v-model:visible="rupModalActive"
            :style="{width: '98%', maxWidth: '500px'}" :modal="true" :closable="false">
      <div class="mb-2">
        <p class="fs-5">
          Ваши действия необратимы. Вы уверены, что хотите утвердить РУП?
        </p>
      </div>
      <template #footer>
        <Button label="Закрыть" icon="pi pi-times" class="p-button-text p-button-danger" @click="closeRupModal"/>
        <Button label="Создать РУП" icon="pi pi-check" class="p-button-text" @click="approveDisciplines"/>
      </template>
    </Dialog>

  </div>


</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";
import SemesterHeader from "@/views/department-head/curriculum-optional-component/UI/SemesterHeader.vue";

export default {
  name: "CurriculumOptionalComponent",
  components: {SemesterHeader},
  data() {
    return {
      isApproved: false,
      btnStatus: false,
      btnSaveStatus: false,
      btnApproveStatus: false,
      btnConfirming: {status: false, discipline_code: ''},
      btnDeleting: {status: false, discipline_code: ''},
      studyLevelId: 0,
      studyCourse: 0,
      studyModuleType: 0,
      studyMinorType: 0,
      chosenMinorTabIndex: 0,
      rupModalActive: false,
      educationProgramCode: 11111,
      studyProgramLanguage: '',
      loadingDisciplines: false,
      colorForFGroup: {},
      colors: ['#E3F2FD', '#E8F5E9', '#FFF3E0', '#E0F2F1', '#FFEBEE'],
      electiveDisciplinesModel: {},
      educationProgramPaginate: {},
      selectedModule: {
        selectedModuleType: '',
        selectedModuleTypes: [{name:'Major', value:'standard'}, {name:'Minor', value:'minor'}]
      },
      studentsChooseToMinor:{
        studentsChooseToMinorModal: false,
        studentsChooseToMinorData:{},
      },
      studentsChoose:{
        w_kv: {
          studentsChooseChangeModal: false,
          studentsChooseChangeData: {},
          studentsChooseChangePostData: {
            students: []
          }
        },
        kv: {
          studentsChooseChangeModal: false,
          studentsChooseChangeData: {},
          studentsChooseChangePostData: {
            students: []
          }
        }
      }
    }
  },
  computed: {
    ...mapState('departmentPractice', ['studyLanguages']),
    ...mapState('docOrdersStudents', ['docOrdersStudents_form']),
    ...mapState('curriculumOptionalComponent', ['optionalComponent', 'groupedEducationPrograms', 'courses', "moduleTypes", "minorTypes"]),
  },
  watch: {
    studyCourse() {
      this.getEducationPrograms()
      this.SET_OPTIONAL_COMPONENT('')
    },
    studyLevelId() {
      this.getEducationPrograms()
      this.SET_OPTIONAL_COMPONENT('')
    },
    educationProgramCode(){
      this.SET_OPTIONAL_COMPONENT('')
    },
    studyProgramLanguage(){
      this.getEducationPrograms()
      this.SET_OPTIONAL_COMPONENT('')
    },
    studyModuleType(){
      this.getEducationPrograms()
    },
    studyMinorType(){
      this.getEducationPrograms()
      if (this.studyMinorType === 2) {
        this.SET_GROUPED_EDUCATION_PROGRAMS('')
      }
    },
  },
  methods: {
    ...mapActions('departmentPractice', ['GET_STUDY_LANGUAGES']),
    ...mapActions('docOrdersStudents', ['GET_STUDY_LEVELS']),
    ...mapActions('curriculumOptionalComponent', ['POST_OPTIONAL_DISCIPLINES',
      'PUT_UPDATE_STUDENTS_DISCIPLINE_CHOOSE',
      'PUT_DISCIPLINE_CONFIRM_STATUS',
      'POST_CREATE_STUDENTS_DISCIPLINE_CHOOSE',
      'POST_MOVE_ELECTABILITY_STUDENTS',
      'GET_OPTIONAL_COMPONENT',
      'GET_GROUPED_EDUCATION_PROGRAMS',
      'GET_COURSES',
      'GET_MINOR_TYPES',
      'GET_MODULE_TYPES',]),

    ...mapMutations('curriculumOptionalComponent', ['SET_OPTIONAL_COMPONENT', 'SET_GROUPED_EDUCATION_PROGRAMS']),

    openRupModal(){
      this.rupModalActive = true
    },

    closeRupModal(){
      this.rupModalActive = false
    },

    onTabModuleChange(event) {
      this.chosenMinorTabIndex = event.index
    },

    openStudentChooseWKVModal(index, semester, module){
      this.studentsChoose.w_kv.studentsChooseChangeModal = true
      this.studentsChoose.w_kv.studentsChooseChangeData = this.optionalComponent.modules[module].semesters[semester]['components'].w_kv[index]
      this.studentsChoose.w_kv.studentsChooseChangePostData.formation_education_program_id = this.optionalComponent.modules[module].semesters[semester]['components'].w_kv[index].formation_education_program_id
      this.studentsChoose.w_kv.studentsChooseChangePostData.semester = semester
    },

    closeStudentChooseWKVModal(){
      this.studentsChoose.w_kv.studentsChooseChangeModal = false
      this.studentsChoose.w_kv.studentsChooseChangeData = {}
      this.studentsChoose.w_kv.studentsChooseChangePostData = {
        students: []
      }
    },

    async createStudentChooseWKV(){
      if (this.studentsChoose.w_kv.studentsChooseChangePostData.students.length){
        let postRes = await this.POST_CREATE_STUDENTS_DISCIPLINE_CHOOSE({
          formation_id: this.studentsChoose.w_kv.studentsChooseChangePostData.formation_education_program_id,
          students: this.studentsChoose.w_kv.studentsChooseChangePostData.students,
          semester: this.studentsChoose.w_kv.studentsChooseChangePostData.semester
        })

        if (postRes){
          this.$message({title: 'Выборность создана!'})
          await this.setOptionalComponentData()
          this.closeStudentChooseWKVModal()
        }
      } else {
        this.$error({title: 'Нужно выбрать минимум 1 студента!'})
      }
    },

    openStudentChooseKVModal(key, semester, module){
      this.studentsChoose.kv.studentsChooseChangeModal = true
      this.studentsChoose.kv.studentsChooseChangeData = this.optionalComponent.modules[module].semesters[semester]['components'].kv[key]
      this.studentsChoose.kv.studentsChooseChangePostData.semester = semester
    },

    closeStudentChooseKVModal(){
      this.studentsChoose.kv.studentsChooseChangeModal = false
      this.studentsChoose.kv.studentsChooseChangeData = {}
      this.studentsChoose.kv.studentsChooseChangePostData = {
        students: []
      }
    },

    async createStudentChooseKV(){
      if (this.studentsChoose.kv.studentsChooseChangePostData.formation_education_program_id){

        let postRes = await this.POST_CREATE_STUDENTS_DISCIPLINE_CHOOSE({
          formation_id: this.studentsChoose.kv.studentsChooseChangePostData.formation_education_program_id,
          students: this.studentsChoose.kv.studentsChooseChangePostData.students,
          semester: this.studentsChoose.kv.studentsChooseChangePostData.semester
        })

        if (postRes){
          this.$message({title: 'Выборность создана!'})
          await this.setOptionalComponentData()
          this.closeStudentChooseKVModal()
        }
      } else {
        this.$error({title: 'Выберите дисциплину!'})
      }
    },

    openStudentChooseToMinorModal(){
      this.studentsChooseToMinor.studentsChooseToMinorModal = true
      this.studentsChooseToMinor.studentsChooseToMinorData.f_group_disciplines = []
      this.studentsChooseToMinor.studentsChooseToMinorData.minor_group_disciplines = []
      for (let semester in this.optionalComponent.modules.major.semesters) {
        for (let fGroup in this.optionalComponent.modules.major.semesters[semester]['components']['kv']) {
          this.optionalComponent.modules.major.semesters[semester]['components']['kv'][fGroup]['disciplines'].forEach(d => {
            if (d.minor_f_group_id){
              this.studentsChooseToMinor.studentsChooseToMinorData.f_group_disciplines.push(d)
            }
          })
        }
      }
      Object.values(this.optionalComponent.modules.minor).forEach(item => {
        for (let semester in item.semesters) {
          item.semesters[semester]['components']['w_kv'].forEach(d => {
            if (d.minor_f_group_id) {
              this.studentsChooseToMinor.studentsChooseToMinorData.minor_group_disciplines.push(d)
            }
          })
        }
      })
    },

    closeStudentChooseToMinorModal(){
      this.studentsChooseToMinor.studentsChooseToMinorModal = false
      this.studentsChooseToMinor.studentsChooseToMinorData = {}
    },

    async changeStudentChooseToMinor(){
      let postRes = await this.POST_MOVE_ELECTABILITY_STUDENTS(this.studentsChooseToMinor.studentsChooseToMinorData.minor_group_disciplines.map(item => item.minor_f_group_id))
      // let postRes = 1

      if (postRes){
        this.$message({title: 'Перенос выполнен успешно!'})
        await this.setOptionalComponentData()
        this.closeStudentChooseToMinorModal()
      }
    },

    async approveDisciplines() {
      this.btnApproveStatus = true
      await this.postOptionalDisciplines()
      this.btnApproveStatus = false
    },

    async postOptionalDisciplines() {
      this.btnStatus = true

      let postData = {
        study_form_id: 1,
        study_course: this.studyCourse,
        education_program_id: this.groupedEducationPrograms.find(i => i.education_speciality_code == this.educationProgramCode).id
      }

      let resPost = await this.POST_OPTIONAL_DISCIPLINES(postData)
      await this.selectEducationProgram()
      if (resPost.success) {
        this.$message({title: 'РУП успешно создан'})
      }

      this.closeRupModal()
      this.btnStatus = false
    },

    async confirmDisciplineStatus(formation_id, discipline_code, semester, f_group_id, confirm_status = null){
      this.btnConfirming.status = true
      this.btnConfirming.discipline_code = discipline_code

      let updateRes = await this.PUT_DISCIPLINE_CONFIRM_STATUS({
        formation_id:formation_id,
        confirm_status:confirm_status
      })

      if (confirm_status !== null && updateRes.success){
        this.$message({title: 'Успешно отменено!'})
      } else {
        this.$message({title: 'Успешно сохранено!'})
      }

      await this.setOptionalComponentData()

      this.btnConfirming.discipline_code = ''
      this.btnConfirming.status = false
    },

    async cancelDisciplineChooses(data){
      this.btnDeleting.status = true
      this.btnDeleting.discipline_code = data.discipline_code

      let updateRes = await this.PUT_UPDATE_STUDENTS_DISCIPLINE_CHOOSE({
        change_formation_id: data.change_formation_id,
        delete_formation_id: data.delete_formation_id
      })

      await this.selectEducationProgram()
      if (updateRes.success){
        this.$message({title: 'Успешно сохранено!'})
      }

      this.btnDeleting.discipline_code = ''
      this.btnDeleting.status = false
    },

    setEducationProgramPaginate() {
      if (this.educationProgramCode !== ''){
        let prevEducationProgram = null
        let currentEducationProgram = null
        let nextEducationProgram = null

        let index = this.groupedEducationPrograms.findIndex(i => i.education_speciality_code == this.educationProgramCode)

        if (index >= 0) {
          currentEducationProgram = this.groupedEducationPrograms[index]

          if (index - 1 >= 0) {
            prevEducationProgram = this.groupedEducationPrograms[index - 1]
          }

          if (index + 1 < this.groupedEducationPrograms.length) {
            nextEducationProgram = this.groupedEducationPrograms[index + 1]
          }
        }

        this.educationProgramPaginate = {
          prev: {
            name: prevEducationProgram?.education_speciality_name,
            code: prevEducationProgram?.education_speciality_code,
            disabled: !prevEducationProgram
          },
          current: {
            name: currentEducationProgram?.education_speciality_name,
            code: currentEducationProgram?.education_speciality_code,
            disabled: !currentEducationProgram
          },
          next: {
            name: nextEducationProgram?.education_speciality_name,
            code: nextEducationProgram?.education_speciality_code,
            disabled: !nextEducationProgram
          }
        }
      }
    },

    getEducationPrograms() {
      this.educationProgramPaginate = {}
      this.educationProgramCode = ''

      if (this.studyLevelId && this.studyCourse && this.studyModuleType && this.studyProgramLanguage && this.studyMinorType !== 2) {
        this.GET_GROUPED_EDUCATION_PROGRAMS({
          studyLevelId: this.studyLevelId,
          studyCourse: this.studyCourse,
          language: this.studyProgramLanguage,
          type: this.studyModuleType
        })
      }
    },

    clear() {
      this.studyLevelId = 0
      this.studyCourse = 0
      this.educationProgramCode = ''
      this.studyProgramLanguage = ''
      this.studyModuleType = 0
    },

    formulateDisciplineModel(d, model) {
      return {
        discipline_code: model?.discipline_code || ((d?.component_status == 1 || d?.component_status == 2) ? d.discipline_code : null),
        component_status: model?.component_status || Number(d.component_status),
        minor_f_group_id: model?.minor_f_group_id || (d.minor_f_group_id ? Number(d.minor_f_group_id) : d.minor_f_group_id),
      }
    },

    async selectEducationProgram(code = '') {
      this.loadingDisciplines = true

      if (this.studyMinorType && this.studyModuleType && this.educationProgramCode !== ''){
        this.$router.push(`/minor-education-program-confirm?minor_education_program_id=${this.educationProgramCode}`)
      } else {
        if (code) {
          this.educationProgramCode = code
        }

        this.setEducationProgramPaginate()
        await this.setOptionalComponentData()
      }
      this.loadingDisciplines = false
    },

    async setOptionalComponentData() {

      if (this.studyLevelId && this.studyCourse && this.studyProgramLanguage && this.educationProgramCode){
        const educationProgram = this.groupedEducationPrograms.find(i => i.education_speciality_code == this.educationProgramCode)

        if (educationProgram) {
          this.isApproved = false
          this.electiveDisciplinesModel = {}
          this.colorForFGroup = {}
          await this.GET_OPTIONAL_COMPONENT({
            code: educationProgram.education_speciality_code,
            admission_year: educationProgram.admission_year,
            study_course: this.studyCourse,
            language: this.studyProgramLanguage
          })

          const electiveDisciplinesModel = {}

          console.log('this.optionalComponent', this.optionalComponent)
          for (let semester in this.optionalComponent.modules.major.semesters) {
            electiveDisciplinesModel[semester] = {}
            for (let fGroup in this.optionalComponent.modules.major.semesters[semester]['components']['kv']) {
              this.optionalComponent.modules.major.semesters[semester]['components']['kv'][fGroup]['disciplines'].forEach(d => {
                if (!this.isApproved && d.component_status == 2) {
                  this.isApproved = true
                }
                electiveDisciplinesModel[semester][d.f_group_id] = this.formulateDisciplineModel(d, electiveDisciplinesModel[semester][d.f_group_id])
                electiveDisciplinesModel[semester][d.f_group_id].formationEducationProgramIds = []
                electiveDisciplinesModel[semester][d.f_group_id].sum_count = 0
              })
            }

            for (let fGroup in this.optionalComponent.modules.major.semesters[semester]['components']['kv']) {
              this.optionalComponent.modules.major.semesters[semester]['components']['kv'][fGroup]['disciplines'].forEach(d => {
                electiveDisciplinesModel[semester][d.f_group_id].formationEducationProgramIds.push(d.formation_education_program_id)
                electiveDisciplinesModel[semester][d.f_group_id].sum_count += Number(d.count)
              })
            }

          }

          this.electiveDisciplinesModel = electiveDisciplinesModel
          console.log('electiveDisciplinesModel', electiveDisciplinesModel)
        }

      } else {
        this.$error({text: 'Выберите Год набора, Уровень обучения, Язык и ОП'})
      }
    },

    findPercentage(number, total) {
      return Math.round((number / total) * 100);
    },

    findPercentageOfTotalShouldChoose(count, should_choose) {
      if (should_choose == 0){
        return count + '(' + 0 + '%)/' + should_choose + '(100%)';
      } else {
        return count + '(' + this.findPercentage(Number(count), Number(should_choose)) + '%)/' + should_choose + '(100%)';
      }
    }
  },
  async mounted() {
    await this.GET_STUDY_LEVELS();
    await this.GET_STUDY_LANGUAGES();
    await this.GET_COURSES();
    await this.GET_MODULE_TYPES();
    await this.GET_MINOR_TYPES();
  }
}
</script>

<style scoped>
.border-light-blue {
  border-color: #dbe1ff !important;
}

.bg-light-blue {
  background-color: #dbe1ff;
}

.bg-minor{
  background: rgb(232, 245, 233);
}
</style>